// Utils
import { uploadFile } from "utils/upload"

// API
import { createRessourceFileUploadURL } from "api/ressources"

interface UploadRessourceProps {
    ressourceId: string,
    files: File[],
    messageId?: string,
    onError?:(e:any)=>void,
    onResult?:(r?:any)=>void
    onFinally?:()=>void
}

export const uploadRessourceFilesAsync = async ({ressourceId, files, onError, onResult, onFinally}:UploadRessourceProps) => {
    const getFileExtension = (fileName: string) => {
        const match = fileName.match(/\.([a-zA-Z0-9]+)$/);
        return match ? match[1] : "";
    };

    const filesToGenerate = files.map((file) => ({
        name: file.name,
        numBytes: file.size,
        extension: getFileExtension(file.name),
    }));

    const [ createUploadPromise ] = createRessourceFileUploadURL({ressourceId, files: filesToGenerate})
    createUploadPromise.then((result:any) => {
        const promises:Promise<unknown>[] = []
        result.map((r:any, i:number) => 
            promises.push(uploadFile(r.uploadPolicy.policy.url, files[i], r.uploadPolicy.policy.fields))
        )
        return Promise.all(promises)
    }).then((result:any) => {
        if(onResult) onResult(result)
    }).catch((e:any) => {
        if(onError) onError(e)
    }).finally(() => {
        if(onFinally) onFinally()
    })
    
}